/* eslint-disable tailwindcss/no-custom-classname */
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { getAllWorkouts } from "../../services/workouts";
import { createNewProgram } from "../../services/programs";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { HiPlus } from "react-icons/hi";
import AsyncSelect from "react-select/async";
import { ProgramDifficultyOptions } from "./list";
import FileUploadZone from "../videos/FileUploadZone";

const AddProgramModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [days, setDays]: any = useState([]);
  const [day, setDay] = useState(0);

  const loadData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });
    const data = response.data.results.workouts;
    return data;
  };

  const [program, setProgram] = useState({
    title: "",
    description: "",
    trainer_id: 0,
    program_duration: 0,
    program_difficulty: "Easy",
    published: false,
    status: 0,
  });

  const rootRef = useRef<HTMLDivElement>(null);

  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [imageType, setImageType] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const formValid = useMemo(() => {
    return (
      program.title.trim() !== "" &&
      program.description.trim() !== "" &&
      program.program_duration != 0 &&
      image != null
    );
  }, [program, image]);

  const handleChange = (e: any) => {
    const data: any = {
      ...program,
    };

    if (e.target.name === "program_duration") {
      const newDayCount = parseInt(e.target.value);

      // Update the days array to match the new program duration
      setDays((prevDays: any[]) => {
        const updatedDays = [...prevDays];
        if (newDayCount > updatedDays.length) {
          // Add nulls if new day count is greater
          for (let i = updatedDays.length; i < newDayCount; i++) {
            updatedDays.push(null);
          }
        } else if (newDayCount < updatedDays.length) {
          // Truncate the array if new day count is smaller
          updatedDays.length = newDayCount;
        }
        return updatedDays;
      });

      setDay(newDayCount);
    }

    data[e.target.name] =
      e.target.name === "published" ? e.target.checked : e.target.value;

    setProgram(data);
  };

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);

    /* Validation */
    if (days?.some((day: any) => day === null)) {
      toast.error("Error: some workout days are empty.");
      setIsSubmitting(false);
      return;
    }

    const new_program = await createNewProgram({
      ...program,
      cover_image: image,
      image_name: imageName,
      image_type: imageType,
      days,
      name,
      file: selectedFile,
    });

    if (new_program.data.error) {
      toast.error(new_program.data.message);
    } else {
      props.getPrograms();
      setOpen(false);
      toast.success("New program created successfully!");
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add Program
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="4xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New Program</strong>
          </Modal.Header>
          <Modal.Body className="!overflow-hidden">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="lastName">Program Duration</Label>
                <div className="mt-1">
                  <TextInput
                    id="program_duration"
                    name="program_duration"
                    placeholder="Program Duration"
                    type="number"
                    defaultValue={0}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="program_difficulty">Difficulty</Label>
                <div className="mt-1">
                  <select
                    id="program_difficulty"
                    name="program_difficulty"
                    value={program.program_difficulty}
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Choose Difficulty
                    </option>
                    {ProgramDifficultyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((x: any) => {
                      return (
                        <>
                          <option value={x.id}>{x.title}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label>Cover Image</Label>
                  <FileUploadZone
                    acceptedTypes={[
                      "image/jpg",
                      "image/png",
                      "image/jpeg",
                      "image/webp",
                    ]}
                    onImageChange={(image) => {
                      setImage(image.dataUri);
                      setImageName(image.name);
                      setImageType(image.type);
                    }}
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>

                <div>
                  <Label>Nutrition Plan</Label>
                  <FileUploadZone
                    acceptedTypes={["application/pdf"]}
                    onDocumentChange={(document) => {
                      setSelectedFile(document.dataUri);
                      setName(document.name);
                    }}
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>
              </div>

              {day > 0 &&
                Array(day)
                  .fill({})
                  .map((d: any, i: any) => {
                    const selectedDay = days?.[i];
                    return (
                      <div className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div className="w-full">
                          <Label>Workout Day</Label>

                          <TextInput
                            id={d}
                            name="day"
                            disabled={true}
                            defaultValue={i + 1}
                          />
                        </div>

                        <div className="w-full">
                          <Label> Choose Workout </Label>
                          <AsyncSelect
                            isClearable={true}
                            loadOptions={loadData}
                            noOptionsMessage={() => "No results"}
                            value={
                              selectedDay?.title
                                ? {
                                    value: selectedDay.workout,
                                    label: selectedDay.title,
                                  }
                                : null
                            }
                            onChange={(
                              selectedOption: {
                                value: string;
                                label: string;
                              } | null,
                            ) => {
                              if (selectedOption === null) {
                                setDays((prevDays: any[]) =>
                                  prevDays.map((day, index) =>
                                    index === i ? null : day,
                                  ),
                                );
                              } else {
                                setDays((prevDays: any[]) =>
                                  prevDays.map((day, index) =>
                                    index === i
                                      ? {
                                          workout: selectedOption.value,
                                          title: selectedOption.label,
                                        }
                                      : day,
                                  ),
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}

              <div className="flex items-start"></div>
            </div>

            <div className="flex flex-col gap-2">
              <Label>Extra Options</Label>
              <div className="">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
              <div className="">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !formValid}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddProgramModal;
