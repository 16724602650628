import { useRef, useState } from "react";
import { Button, Modal } from "flowbite-react";
import { HiPencil } from "react-icons/hi";
import { toast } from "react-toastify";

import { updateProgramsOrder } from "../../services/programs";
import ModalBodyContent from "./ModalBodyContent";

const EditProgramLibrary = function () {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const [updatedPrograms, setUpdatedPrograms] = useState<any[]>([]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const simplified = updatedPrograms.reduce(
      (acc: any, program: any, index: any) => {
        const programEntry = {
          id: program.id,
          order: index,
        };
        acc.push(programEntry);
        return acc;
      },
      [],
    );
    await updateProgramsOrder({
      programs: simplified,
    });
    setIsSubmitting(false);
    setOpen(false);
    toast.success("Program Library Saved Successfully!");
  };

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPencil className="text-xl" />
            Edit Program Library
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Program Library</strong>
          </Modal.Header>
          <Modal.Body>
            <ModalBodyContent
              onChange={(programs: any) => setUpdatedPrograms(programs)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditProgramLibrary;
