/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  createNewWorkout,
  getWorkoutCategories,
} from "../../services/workouts";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { HiPlus } from "react-icons/hi";

import { difficulty } from "./data.json";
import { IntensityLevel } from "./EditWorkoutModal";
import FileUploadZone from "../videos/FileUploadZone";

const AddWorkoutModal = function (props: any) {
  const rootRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [workout, setWorkout]: any = useState({
    title: "",
    description: "",
    category: null,
    difficulty: null,
    trainer: null,
    rounds: null,
    published: false,
    featured: false,
  });

  const [categories, setCategories] = useState([]);
  const [image, setImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [imageType, setImageType]: any = useState(null);

  const [imageMobile, setImageMobile]: any = useState(null);
  const [imageNameMobile, setImageNameMobile]: any = useState(null);
  const [imageTypeMobile, setImageTypeMobile]: any = useState(null);

  const formValid = useMemo(() => {
    return (
      workout.title.trim() !== "" &&
      workout.description.trim() !== "" &&
      workout.category != null &&
      workout.difficulty != null &&
      workout.trainer != null &&
      workout.rounds != null &&
      image != null &&
      imageMobile != null
    );
  }, [workout, image, imageMobile]);

  const intensityArray = [
    { id: "Auto Assign", title: "Auto Assign" },
    ...Object.entries(IntensityLevel).map(([key, value]) => ({
      id: value,
      title: key,
    })),
  ];

  const handleChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setWorkout((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const new_workout = await createNewWorkout({
        ...workout,
        cover_image: image,
        cover_image_mobile: imageMobile,
        image_name: imageName,
        image_type: imageType,
        image_name_mobile: imageNameMobile,
        image_type_mobile: imageTypeMobile,
      });

      if (new_workout.data.error) {
        toast.error(
          `Unable to create new workout: ${new_workout.data.message}`,
        );
      } else {
        props.getWorkouts();
        setOpen(false);
        toast.success("New workout created successfully!");
      }
    } catch (e: any) {
      const reqMessage = e?.response?.data?.message;
      const message = Array.isArray(reqMessage)
        ? reqMessage.join(", ")
        : reqMessage;
      toast.error(`Unable to create new workout: ${message || e.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getWorkoutCategories();
      setCategories(response.data);
    } catch {
      toast.error("Failed to load workout categories");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add Workout
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New Workout</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>Category</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Difficulty</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="difficulty"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {difficulty.map((x: any, i: any) => {
                      return (
                        <>
                          <option value={i}>{x}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((x: any) => {
                      return (
                        <>
                          <option value={x.id}>{x.title}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Intensity</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="intensity"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {intensityArray.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={item.id === "Auto Assign"}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Rounds</Label>
                <div className="mt-1">
                  <select
                    name="rounds"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    <option value="1">x1</option>
                    <option value="2">x2</option>
                    <option value="3">x3</option>
                    <option value="4">x4</option>
                    <option value="5">x5</option>
                    <option value="6">x6</option>
                    <option value="7">x7</option>
                  </select>
                </div>
              </div>

              <div className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label>Cover Image</Label>
                  <FileUploadZone
                    acceptedTypes={[
                      "image/jpg",
                      "image/png",
                      "image/jpeg",
                      "image/webp",
                    ]}
                    onImageChange={(image) => {
                      setImage(image.dataUri);
                      setImageName(image.name);
                      setImageType(image.type);
                    }}
                    defaultPreview={{
                      name: imageName,
                      url: image,
                      type: imageType,
                    }}
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>

                <div>
                  <Label>Cover Image Mobile</Label>
                  <FileUploadZone
                    acceptedTypes={[
                      "image/jpg",
                      "image/png",
                      "image/jpeg",
                      "image/webp",
                    ]}
                    onImageChange={(image) => {
                      setImageMobile(image.dataUri);
                      setImageNameMobile(image.name);
                      setImageTypeMobile(image.type);
                    }}
                    defaultPreview={{
                      name: imageNameMobile,
                      url: imageMobile,
                      type: imageTypeMobile,
                    }}
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !formValid}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddWorkoutModal;
