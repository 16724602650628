/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useRef, useState } from "react";
import { getAllWorkouts } from "../../services/workouts";
import { programExercises, updateProgram } from "../../services/programs";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { HiPlus } from "react-icons/hi";
import AsyncSelect from "react-select/async";
import { ProgramDifficultyOptions } from "./list";
import FileUploadZone from "../videos/FileUploadZone";
import { isCancelReq } from "../../util/axios";

const EditProgramModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [days, setDays]: any = useState([]);
  const [day, setDay]: any = useState(0);

  const [program, setProgram]: any = useState({});

  const [image, setImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [imageType, setImageType]: any = useState(null);

  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile]: any = useState(null);

  const rootRef = useRef<HTMLDivElement>(null);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        const exercises = await programExercises(props.program.id);

        const exercises_data = exercises.data.results;

        const _days = exercises_data.map((x: any, i: number) => {
          return {
            day: x.workout_day,
            workout: x.workout_id,
            title: x.workout_title,
            position: i,
          };
        });

        setDays(_days);

        setDay(props.program.program_duration);
        setProgram(props.program);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, [props.program]);

  const loadData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });

    const data = response.data.results.workouts;

    return data;
  };

  const handleChange = (e: any) => {
    const data: any = {
      ...program,
    };

    if (!touchedFields.includes(e.target.name)) {
      setTouchedFields([...touchedFields, e.target.name]);
    }

    if (e.target.name === "program_duration") {
      const newDayCount = parseInt(e.target.value);

      // Update the days array to match the new program duration
      setDays((prevDays: any[]) => {
        const updatedDays = [...prevDays];
        if (newDayCount > updatedDays.length) {
          // Add nulls if new day count is greater
          for (let i = updatedDays.length; i < newDayCount; i++) {
            updatedDays.push(null);
          }
        } else if (newDayCount < updatedDays.length) {
          // Truncate the array if new day count is smaller
          updatedDays.length = newDayCount;
        }
        return updatedDays;
      });

      setDay(newDayCount);
    }

    data[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setProgram(data);
  };

  const handleSubmit = async () => {
    setUpdate(true);

    /* Validation */
    if (days?.some((day: any) => day === null)) {
      toast.error("Error: some workout days are empty.");
      setUpdate(false);
      return;
    }

    const updatedFields: any = {};
    touchedFields.forEach((field: any) => {
      updatedFields[field] = program[field];
    });

    const update_program = await updateProgram(
      {
        ...updatedFields,
        ...(image ? { cover_image: image } : {}),
        ...(imageName ? { image_name: imageName } : {}),
        ...(imageType ? { image_type: imageType } : {}),
        ...(days ? { days } : {}),
        ...(name ? { name } : {}),
        ...(selectedFile ? { file: selectedFile } : {}),
      },
      props.program.id,
    );

    if (update_program.data.error) {
      toast.error(update_program.data.message);
    } else {
      props.getPrograms();
      setOpen(false);
      toast.success("Program updated successfully!");
    }

    setUpdate(false);
  };

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-3">
          <HiPlus className="text-xl" />
          Edit Program
        </div>
      </Button>
      <div ref={rootRef}>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="4xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Program</strong>
          </Modal.Header>
          <Modal.Body className="!overflow-hidden">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    defaultValue={props.program.title}
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    defaultValue={props.program.description}
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="lastName">Program Duration</Label>
                <div className="mt-1">
                  <TextInput
                    id="program_duration"
                    name="program_duration"
                    placeholder="Program Duration"
                    type="number"
                    defaultValue={props.program.program_duration}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="program_difficulty">Difficulty</Label>
                <div className="mt-1">
                  <select
                    id="program_difficulty"
                    name="program_difficulty"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Choose Difficulty
                    </option>
                    {ProgramDifficultyOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        selected={option.value === program.program_difficulty}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Athlete</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((option: any) => (
                      <option
                        key={option.id}
                        value={option.id}
                        selected={option.id === program.trainer_id}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <Label>Cover Image</Label>
                  <FileUploadZone
                    acceptedTypes={[
                      "image/jpg",
                      "image/png",
                      "image/jpeg",
                      "image/webp",
                    ]}
                    onImageChange={(image) => {
                      setImage(image.dataUri);
                      setImageName(image.name);
                      setImageType(image.type);
                    }}
                    defaultPreview={{
                      url: props?.program?.cover_image,
                    }}
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>

                <div>
                  <Label>Nutrition Plan</Label>
                  <FileUploadZone
                    acceptedTypes={["application/pdf"]}
                    onDocumentChange={(document) => {
                      setSelectedFile({
                        fileData: document.dataUri,
                        fileName: document.name,
                      });
                      setName(document.name);
                    }}
                    defaultPreview={
                      props?.program?.nutrition_pdf
                        ? {
                            url: props?.program?.nutrition_pdf,
                            type: "application/pdf",
                          }
                        : undefined
                    }
                    maxDimensions={{ width: 2000, height: 2000 }}
                    maxSize={10 * 1024 * 1024}
                  />
                </div>
              </div>

              {day > 0 &&
                Array(day)
                  .fill({})
                  .map((d: any, i: any) => {
                    const selectedDay = days?.[i];
                    return (
                      <div
                        key={i}
                        className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2"
                      >
                        <div className="mt-2 w-full">
                          <Label>Workout Day</Label>

                          <TextInput
                            id={d}
                            name="day"
                            disabled={true}
                            defaultValue={i + 1}
                          />
                        </div>

                        <div className="w-full">
                          <Label> Choose Workout </Label>
                          <AsyncSelect
                            isClearable={true}
                            className="mt-2"
                            value={
                              selectedDay?.title
                                ? {
                                    value: selectedDay.workout,
                                    label: selectedDay.title,
                                  }
                                : null
                            }
                            loadOptions={loadData}
                            noOptionsMessage={() => "No results"}
                            onChange={(
                              selectedOption: {
                                value: string;
                                label: string;
                              } | null,
                            ) => {
                              if (selectedOption === null) {
                                setDays((prevDays: any[]) =>
                                  prevDays.map((day, index) =>
                                    index === i ? null : day,
                                  ),
                                );
                              } else {
                                setDays((prevDays: any[]) =>
                                  prevDays.map((day, index) =>
                                    index === i
                                      ? {
                                          workout: selectedOption.value,
                                          title: selectedOption.label,
                                        }
                                      : day,
                                  ),
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
            </div>

            <div className="flex flex-col gap-2 mt-6">
              <Label>Extra Options</Label>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="featured"
                  className="peer sr-only"
                  onChange={handleChange}
                  defaultChecked={props.program?.featured}
                />
                <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Featured
                </span>
              </label>

              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="published"
                  className="peer sr-only"
                  onChange={handleChange}
                  defaultChecked={props.program.published}
                />
                <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Published
                </span>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={handleSubmit} disabled={update}>
              {update ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditProgramModal;
