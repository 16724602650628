/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, type FC, useState } from "react";
import "svgmap/dist/svgMap.min.css";
import { CgGym } from "react-icons/cg";
import NavbarSidebarLayout from "../layouts/navbar-sidebar";
import { HiPaperClip, HiUsers, HiVideoCamera } from "react-icons/hi";
import { HiUserGroup as UsersIcon } from "react-icons/hi";
import { getTotalUsers } from "../services/user";
import { getTotalTrainers } from "../services/trainers";
import { getTotalWorkouts } from "../services/workouts";
import { getTotalPrograms } from "../services/programs";
import { getTotalVideos } from "../services/videos";
import { isCancelReq } from "../util/axios";

const DashboardPage: FC = function () {
  const [users, setUsers] = useState(0);
  const [trainers, setTrainers] = useState(0);
  const [workouts, setWorkouts] = useState(0);
  const [programs, setPrograms] = useState(0);
  const [videos, setVideos] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        const total_users = await getTotalUsers();
        setUsers(total_users.data?.results?.total);

        const total_trainers = await getTotalTrainers();
        setTrainers(total_trainers.data?.results?.total);

        const total_workouts = await getTotalWorkouts();
        setWorkouts(total_workouts.data?.results?.total);

        const total_programs = await getTotalPrograms();
        setPrograms(total_programs.data?.results?.total);

        const total_videos = await getTotalVideos();
        setVideos(total_videos.data?.results?.total);
      } catch (error: any) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, []);
  const stats = [
    {
      id: 1,
      name: "Total Users",
      stat: users,
      icon: UsersIcon,
      change: "",
      changeType: "",
    },
    {
      id: 2,
      name: "Total Athletes",
      stat: trainers,
      icon: HiUsers,
      change: "",
      changeType: "",
    },
    {
      id: 3,
      name: "Total Videos",
      stat: videos,
      icon: HiVideoCamera,
      change: "",
      changeType: "",
    },
    {
      id: 3,
      name: "Total Workouts",
      stat: workouts,
      icon: CgGym,
      change: "",
      changeType: "",
    },

    {
      id: 3,
      name: "Total Programs",
      stat: programs,
      icon: HiPaperClip,
      change: "",
      changeType: "",
    },
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <NavbarSidebarLayout>
      <div className="px-4 pt-6">
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Overview
          </h3>

          <dl className="mt-5 grid grid-cols-1 gap-5 dark:bg-gray-900 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden  rounded-lg bg-white px-4 pb-12 pt-5 shadow dark:bg-primary-200 dark:text-primary-800 sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-indigo-500 p-3">
                    <item.icon
                      className="size-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    {item.name}
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </p>
                  <p
                    className={classNames(
                      item.changeType === "increase"
                        ? "text-green-600"
                        : "text-red-600",
                      "ml-2 flex items-baseline text-sm font-semibold",
                    )}
                  ></p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

export default DashboardPage;
