/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import { Sidebar, TextInput } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";

import { CgGym } from "react-icons/cg";
import { GiWeightLiftingUp } from "react-icons/gi";
import {
  HiChartBar,
  HiChartPie,
  HiPaperClip,
  HiSearch,
  HiUserGroup,
  HiUsers,
  HiVideoCamera,
} from "react-icons/hi";
import { IoIosNotifications } from "react-icons/io";

import { useSidebarContext } from "../context/SidebarContext";
import isSmallScreen from "../helpers/is-small-screen";

const ExampleSidebar: FC = function () {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();

  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
  }, [setCurrentPage]);

  return (
    <div
      className={classNames("lg:!block", {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <form className="pb-3 md:hidden">
              <TextInput
                icon={HiSearch}
                type="search"
                placeholder="Search"
                required
                size={32}
              />
            </form>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  href="/"
                  icon={HiChartPie}
                  className={
                    "/" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
                  }
                >
                  Dashboard
                </Sidebar.Item>

                <Sidebar.Item
                  href="/programs"
                  icon={HiPaperClip}
                  className={
                    "/programs" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Programs
                </Sidebar.Item>

                <Sidebar.Item
                  href="/workouts"
                  icon={CgGym}
                  className={
                    "/workouts" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Workouts
                </Sidebar.Item>

                <Sidebar.Item
                  href="/exercises"
                  icon={GiWeightLiftingUp}
                  className={
                    "/exercises" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Exercises
                </Sidebar.Item>

                <Sidebar.Item
                  href="/videos"
                  icon={HiVideoCamera}
                  className={
                    "/videos" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Videos
                </Sidebar.Item>

                <Sidebar.Item
                  href="/trainers"
                  icon={HiUserGroup}
                  className={
                    "/trainers" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Athletes
                </Sidebar.Item>

                <Sidebar.Item
                  href="/users/list"
                  icon={HiUsers}
                  className={
                    "/users/list" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Users
                </Sidebar.Item>

                <Sidebar.Item
                  href="/spotlight"
                  icon={HiChartBar}
                  className={
                    "/spotlight" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Spotlight
                </Sidebar.Item>
                <Sidebar.Item
                  href="/notifications"
                  icon={IoIosNotifications}
                  className={
                    "/notifications" === currentPage
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Notifications
                </Sidebar.Item>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ExampleSidebar;
