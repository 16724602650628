/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useRef, useState } from "react";
import { getWorkoutCategories, updateWorkout } from "../../services/workouts";
import { toast } from "react-toastify";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { difficulty, rounds } from "./data.json";
import { HiPlus } from "react-icons/hi";
import FileUploadZone from "../videos/FileUploadZone";

export enum IntensityLevel {
  X1 = "X1",
  X2 = "X2",
  X3 = "X3",
  X4 = "X4",
  X5 = "X5",
  X6 = "X6",
  X7 = "X7",
}

const EditWorkoutModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [updatedWorkout, setUpdatedWorkout]: any = useState({});

  // Array of touched fields
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  const rootRef = useRef<HTMLDivElement>(null);

  const [categories, setCategories] = useState([]);
  const [image, setImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [imageType, setImageType]: any = useState(null);

  const [imageMobile, setImageMobile]: any = useState(null);
  const [imageNameMobile, setImageNameMobile]: any = useState(null);
  const [imageTypeMobile, setImageTypeMobile]: any = useState(null);

  const intensityArray = [
    { id: "Auto Assign", title: "Auto Assign" },
    ...Object.entries(IntensityLevel).map(([key, value]) => ({
      id: value,
      title: key,
    })),
  ];

  const handleChange = (e: any) => {
    const { name, checked, value } = e.target;
    const newValue =
      name === "published" || name === "featured" ? checked : value;

    setUpdatedWorkout((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));

    // Mark field as touched if not already
    if (!touchedFields.includes(name)) {
      setTouchedFields((prev) => [...prev, name]);
    }
  };

  const handleSubmit = async () => {
    setUpdate(true);

    // Build an object with only touched fields
    const changedData = touchedFields.reduce(
      (acc, field) => {
        acc[field] = updatedWorkout[field];
        return acc;
      },
      {} as Record<string, any>,
    );

    if (changedData["intensity"] === "Auto Assign") {
      delete changedData["intensity"];
    }

    const update_workout = await updateWorkout(
      {
        ...changedData,
        ...(image ? { cover_image: image } : {}),
        ...(imageMobile ? { cover_image_mobile: imageMobile } : {}),
        ...(imageName ? { image_name: imageName } : {}),
        ...(imageType ? { image_type: imageType } : {}),
        ...(imageNameMobile ? { image_name_mobile: imageNameMobile } : {}),
        ...(imageTypeMobile ? { image_type_mobile: imageTypeMobile } : {}),
      },
      props.workout.id,
    );

    if (update_workout.data.error) {
      toast.error(update_workout.data.message);
    } else {
      props.getWorkouts();
      setOpen(false);
      toast.success("Workout updated successfully!");
    }

    setUpdate(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await getWorkoutCategories();
      setCategories(response.data);
    } catch {
      toast.error("Failed to load workout categories");
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Edit Workout
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Workout - {props.workout.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-1">
                  <TextInput
                    id="title"
                    name="title"
                    defaultValue={props.workout.title}
                    placeholder="Title"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="lastName">Description</Label>
                <div className="mt-1">
                  <Textarea
                    id="description"
                    name="description"
                    defaultValue={props.workout.description}
                    placeholder="description"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <Label>Category</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {categories.map((category: any) => (
                      <option
                        key={category.id}
                        value={category.id}
                        selected={props.workout.category_id === category.id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Label>Difficulty</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="difficulty"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {difficulty.map((x: any, i: number) => {
                      if (props.workout.difficulty == i) {
                        return (
                          <option key={i} value={i} selected>
                            {x}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={i}>
                            {x}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Trainer</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="trainer"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {props.trainers.map((trainer: any, i: any) => {
                      if (props.workout.trainer_id === trainer.id) {
                        return (
                          <option key={i} value={trainer.id} selected>
                            {trainer.title}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={trainer.id}>
                            {trainer.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Select Intensity</Label>
                <div className="mt-1">
                  <select
                    onChange={handleChange}
                    name="intensity"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {intensityArray.map((item, i) => {
                      return (
                        <option
                          key={i}
                          value={item.id}
                          selected={props.workout.intensity === item.id}
                        >
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div>
                <Label>Rounds</Label>
                <div className="mt-1">
                  <select
                    name="rounds"
                    onChange={handleChange}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    {rounds.map((x: any, i: number) => {
                      if (props.workout.rounds - 1 === i) {
                        return (
                          <option key={i} value={i + 1} selected>
                            {x}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={i + 1}>
                            {x}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>

              {/* Empty space */}
              <div></div>

              <div>
                <Label>Cover Image</Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpg",
                    "image/png",
                    "image/jpeg",
                    "image/webp",
                  ]}
                  onImageChange={(image) => {
                    setImage(image.dataUri);
                    setImageName(image.name);
                    setImageType(image.type);
                  }}
                  defaultPreview={
                    props?.workout?.cover_image
                      ? {
                          name: props?.workout?.cover_image,
                          url: props?.workout?.cover_image,
                          type: "image/*",
                        }
                      : undefined
                  }
                  maxDimensions={{ width: 2000, height: 2000 }}
                  maxSize={10 * 1024 * 1024}
                />
              </div>

              <div>
                <Label>Cover Image Mobile</Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpg",
                    "image/png",
                    "image/jpeg",
                    "image/webp",
                  ]}
                  onImageChange={(image) => {
                    setImageMobile(image.dataUri);
                    setImageNameMobile(image.name);
                    setImageTypeMobile(image.type);
                  }}
                  defaultPreview={
                    props?.workout?.cover_image_mobile
                      ? {
                          name: props?.workout?.cover_image_mobile,
                          url: props?.workout?.cover_image_mobile,
                          type: "image/*",
                        }
                      : undefined
                  }
                  maxDimensions={{ width: 2000, height: 2000 }}
                  maxSize={10 * 1024 * 1024}
                />
              </div>

              <div className="flex items-start"></div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="published"
                    className="peer sr-only"
                    onChange={handleChange}
                    defaultChecked={props.workout.published}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Published
                  </span>
                </label>
              </div>
            </div>

            <div>
              <div className="mt-10">
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    name="featured"
                    className="peer sr-only"
                    onChange={handleChange}
                    defaultChecked={props.workout.featured}
                  />
                  <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                  <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Featured
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="primary" onClick={handleSubmit} disabled={update}>
              {update ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditWorkoutModal;
