import { toast } from "react-toastify";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useEffect } from "react";
import {
  getAllEquipment,
  getAllExerciseTypes,
  getAllMuscles,
  getExerciseDetails,
} from "../../services/exercises";
import type { Equipment, ExerciseForm, Muscle } from "../../types/apiResponses";
import { useQuery } from "@tanstack/react-query";

export const blueThemeSpinner: CustomFlowbiteTheme["spinner"] = {
  color: {
    info: "fill-blue-700",
  },
};

export const ThemeTooltip: CustomFlowbiteTheme["tooltip"] = {
  target: "w-full",
};

export interface ExerciseModalProps {
  id?: string;
  updating: boolean;
  exercise: ExerciseForm;
  isOpen: boolean;
  setExercise: (exercise: ExerciseForm) => void;
  handleSubmit: () => void;
  handleClose: () => void;
}

const ExerciseModal = function (props: ExerciseModalProps) {
  const {
    id,
    isOpen,
    updating,
    exercise,
    setExercise,
    handleSubmit,
    handleClose,
  } = props;

  const typesData = useQuery({
    queryKey: ["typesData"],
    queryFn: () => fetchTypesData(),
  });

  const exerciseDetails = useQuery({
    queryKey: ["exerciseDetails", id],
    queryFn: () => fetchExerciseDetails(),
  });

  const equipment = useQuery({
    queryKey: ["equipment"],
    queryFn: async () => {
      const response = await getAllEquipment();
      return response;
    },
  });

  // Add this to check if any query is loading
  const isLoading =
    typesData.isLoading || exerciseDetails.isLoading || equipment.isLoading;

  const fetchExerciseDetails = async () => {
    if (id) {
      const exerciseData = await getExerciseDetails(id);
      return {
        id: exerciseData.results.id,
        name: exerciseData.results.name,
        exerciseType: exerciseData.results.exerciseType.id,
        equipment: exerciseData.results.equipment?.id || "",
        muscles: exerciseData.results.muscles.map((x: any) => x.id),
      };
    } else {
      return {
        id: "",
        name: "",
        exerciseType: "",
        equipment: "1f38ca0b-73eb-499f-8074-8daa7ec4c1b8", // No equipment
        muscles: [],
      };
    }
  };

  const fetchTypesData = async () => {
    const musclesData = (await getAllMuscles()).results;
    const typesData = (await getAllExerciseTypes()).results;
    return {
      typesData,
      musclesData,
    };
  };

  useEffect(() => {
    if (exerciseDetails.data) setExercise(exerciseDetails.data);
  }, [exerciseDetails.data]);

  const handleInput = (e: any) => {
    setExercise({ ...exercise, [e.target.name]: e.target.value });
  };

  const handleSelectMuscle = (e: any) => {
    if (exercise.muscles.includes(e.target.value)) {
      setExercise({
        ...exercise,
        muscles: exercise.muscles.filter((x) => x !== e.target.value),
      });
    } else {
      setExercise({
        ...exercise,
        muscles: [...exercise.muscles, e.target.value],
      });
    }
  };
  const handleSelectType = (e: any) => {
    setExercise({
      ...exercise,
      exerciseType: e.target.value,
    });
  };

  const handleSelectEquipment = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setExercise({
      ...exercise,
      equipment: e.target.value,
    });
  };

  const validateForm = () => {
    if (!exercise.exerciseType) {
      toast.error("Please select an Exercise Type");
      return false;
    }
    return true;
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      await handleSubmit();
      handleClose();
    }
  };

  return (
    <Modal size="3xl" position="top-center" show={isOpen} onClose={handleClose}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{id ? "Edit" : "Add"} Exercise</strong>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="flex min-h-52 items-center justify-center ">
            <Spinner theme={blueThemeSpinner} color="info" size="xl" />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="name" className="font-semibold">
                  Name
                </Label>
                <div className="mt-1">
                  <TextInput
                    id={`name-${props.exercise.id}`}
                    name="name"
                    value={exercise?.name}
                    placeholder="Name"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div>
                <Label className="font-semibold">Exercise Type</Label>
                <div className="mt-1">
                  <select
                    onChange={handleSelectType}
                    name="exercise"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {typesData.data?.typesData.map((x: any) => {
                      return (
                        <option
                          key={x.id}
                          value={x.id}
                          selected={x.id === exercise.exerciseType}
                        >
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div>
                <Label className="font-semibold">Equipment</Label>
                <div className="mt-1">
                  <select
                    onChange={handleSelectEquipment}
                    value={exercise.equipment}
                    name="equipment"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" selected disabled>
                      Choose
                    </option>
                    {equipment.data?.map((item: Equipment) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="py-4">
              <Label className="font-semibold">Muscle Target</Label>
              {typesData.data?.musclesData &&
              typesData.data?.musclesData.length > 0 ? (
                <div className="mt-4 grid grid-cols-4 gap-6">
                  {typesData.data?.musclesData.map(
                    (muscle: Muscle, index: number) => (
                      <div key={index} className="flex items-center gap-2">
                        <Button
                          onClick={() =>
                            handleSelectMuscle({ target: { value: muscle.id } })
                          }
                          className={`w-full ${exercise?.muscles?.includes(muscle.id) ? "bg-primary-700" : "bg-primary-300"}`}
                        >
                          {muscle.name}
                        </Button>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <div className="flex min-h-52 w-full items-center justify-center ">
                  <Spinner theme={blueThemeSpinner} color="info" size="xl" />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {id ? (
          <Button
            color="primary"
            disabled={updating}
            onClick={handleFormSubmit}
          >
            {updating ? "Updating..." : "Update"}
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={updating}
            onClick={handleFormSubmit}
          >
            {updating ? "Creating..." : "Create"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ExerciseModal;
