import {
  Breadcrumb,
  Label,
  Pagination,
  Table,
  TextInput,
} from "flowbite-react";
import type { FC } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { HiHome } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { getUsers } from "../../redux/actionCreators/users";
import ActionType from "../../redux/actionTypes";
import AddUserModal from "./AddUserModal";
import ChangeUserPassword from "./ChangeUserPassword";
import EditUserModal from "./EditUserModal";
import { debounce } from "lodash";
import { FaMagnifyingGlass } from "react-icons/fa6";

interface RootState {
  auth: any;
  users: any;
}

const MAX_CONTENT_PER_PAGE = 10;

const UserListPage: FC = function () {
  const dispatch: any = useDispatch();
  const state: any = useSelector((state: RootState) => state);

  const [users, setUsers] = useState<{ total: number; data: any[] }>({
    total: 0,
    data: [],
  });
  const [loading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  // Reference to store the current AbortController (so we can cancel requests)
  const abortControllerRef = useRef<AbortController | null>(null);

  const debouncedSearch = useCallback((value: string) => {
    debounce(() => {
      setSearch(value);
    }, 300)();
  }, []);

  /**
   * getAllUsers
   * Fetches users from the server while canceling any existing request.
   */
  const getAllUsers = useCallback(
    async (page: any = currentPage) => {
      // Cancel any ongoing request before making a new one
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the next request
      abortControllerRef.current = new AbortController();

      setIsLoading(true);

      dispatch(
        getUsers({
          page,
          take: MAX_CONTENT_PER_PAGE,
          search,
          signal: abortControllerRef.current.signal,
        }),
      );
    },
    [currentPage, dispatch, search],
  );

  /**
   * onPageChange
   * Sets the new page for pagination.
   */
  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Trigger fetch whenever currentPage or search changes
  useEffect(() => {
    getAllUsers(currentPage);
  }, [currentPage, getAllUsers, search]);

  // Respond to Redux store updates
  useEffect(() => {
    if (state.users.event === ActionType.GET_ALL_USERS) {
      setUsers(state.users.users);
      setIsLoading(false);
    } else if (state?.users?.type === ActionType.GET_ALL_USERS_FAILED) {
      console.error("Error: unable to fetch users");
      setIsLoading(false);
    }
  }, [state]);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/users/list">Users</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              All users
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <form className="lg:pr-3">
                <Label htmlFor="users-search" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    id="users-search"
                    name="users-search"
                    placeholder="Search for users"
                    onChange={(e: any) => {
                      // Cancel previous request on new search input
                      debouncedSearch(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <AddUserModal getUsers={getAllUsers} />
            </div>
          </div>
        </div>
      </div>
      <div className=" flex h-full flex-col">
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {loading ? (
                <div role="status" className="text-center">
                  <svg
                    aria-hidden="true"
                    className="mx-auto my-10 size-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 
                        50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 
                        22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 
                        100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 
                        73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 
                        90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 
                        72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 
                        27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 
                        97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 
                        20.348C85.8452 15.1192 80.8826 10.7238 75.2124 
                        7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 
                        1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 
                        1.27873C39.2613 1.69328 37.813 4.19778 38.4501 
                        6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 
                        10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 
                        10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 
                        15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 
                        25.841C84.9175 28.9121 86.7997 32.2913 88.1811 
                        35.8758C89.083 38.2158 91.5421 39.6781 93.9676 
                        39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="h-full overflow-y-hidden pb-4">
                  <AllUsersTable
                    users={users}
                    state={loading}
                    getUsers={getAllUsers}
                  />
                  {users?.total !== users?.data?.length && (
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                      showIcons
                      aria-disabled={true}
                      totalPages={Math.ceil(
                        Number(users?.total || 1) / MAX_CONTENT_PER_PAGE,
                      )}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </NavbarSidebarLayout>
  );
};

const AllUsersTable = function (props: any) {
  const formatDate = (dateString: string) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(dateString));
  };

  if (props.users?.data?.length === 0) {
    return (
      <div className="flex h-[21rem] w-full items-center justify-center">
        <div className="flex size-40 flex-col items-center justify-center gap-3 rounded-full  bg-gray-100 p-4 text-gray-400">
          <FaMagnifyingGlass size={45} />
          <span className="text-sm font-normal">No users found</span>
        </div>
      </div>
    );
  }

  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell>User</Table.HeadCell>
        <Table.HeadCell>Creation Date</Table.HeadCell>
        <Table.HeadCell>Activity Status</Table.HeadCell>
        <Table.HeadCell>Auth Provider</Table.HeadCell>
        <Table.HeadCell>Daily Average Played</Table.HeadCell>
        <Table.HeadCell>Total Time Played</Table.HeadCell>
        <Table.HeadCell>Last Login</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {props.users?.data?.map((user: any, index: number) => {
          return (
            <Table.Row
              key={index}
              className="hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap lg:mr-0">
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  <div className="text-base font-semibold text-gray-900 dark:text-white">
                    {user?.name || "Unknown"}
                  </div>
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    {user.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                {formatDate(user.created_at as string)}
              </Table.Cell>
              <Table.Cell>
                <div className="text-sm font-medium text-gray-900 dark:text-white text-center">
                  {user.activity_status !== null ? user.activity_status : "-"}
                </div>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap text-sm font-medium capitalize text-gray-900 dark:text-white">
                {user.identity_provider}
              </Table.Cell>
              <Table.Cell>
                <div className="text-sm font-medium text-gray-900 dark:text-white text-center">
                  {user.daily_average_minutes_played !== null
                    ? user.daily_average_minutes_played
                    : "-"}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="text-sm font-medium text-gray-900 dark:text-white text-center">
                  {user.total_minutes_played !== null
                    ? user.total_minutes_played
                    : "-"}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  {user.last_login_timestamp &&
                    Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(user.last_login_timestamp))}
                </div>
              </Table.Cell>
              <Table.Cell className="whitespace-nowrap p-4 text-sm font-normal text-gray-900 dark:text-white">
                <div className="flex items-center">
                  <div
                    className={`mr-2 size-2.5 rounded-full ${
                      user.status === "STATUS_ACTIVE"
                        ? "bg-green-400"
                        : "bg-red-400"
                    }`}
                  />
                  {user.status === "STATUS_ACTIVE" ? "Active" : "Not Active"}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center gap-x-3 whitespace-nowrap">
                  <EditUserModal user={user} getUsers={props.getUsers} />
                  <ChangeUserPassword user={user} getUsers={props.getUsers} />
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default UserListPage;
