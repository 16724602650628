import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { FaCircleInfo } from "react-icons/fa6";
import { MdDragIndicator } from "react-icons/md";
import SortableItem from "./SortableItem";
import { useEffect, useState, useTransition } from "react";
import { getAllPrograms } from "../../services/programs";
import { Spinner } from "flowbite-react";
import { blueThemeSpinner } from "../exercises/ExerciseModal";
import { isCancelReq } from "../../util/axios";

const getSortedPrograms = async () => {
  const programs = await getAllPrograms({
    page: 1,
    take: 1000,
    visibility: "public",
  });

  const filtered = programs.results.data.filter(
    (program: any) => program.published,
  );
  const sorted = filtered.sort(
    (a: { order: number }, b: { order: number }) => a.order - b.order,
  );

  return sorted;
};

const ModalBodyContent = ({ onChange }: any) => {
  const [programs, setPrograms] = useState<any[]>([]);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    startTransition(async () => {
      try {
        const sortedPrograms = await getSortedPrograms();
        setPrograms(sortedPrograms);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    });
  }, []);

  const onDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setPrograms((items: any) => {
        const oldIndex = items.findIndex((item: any) => item.id === active.id);
        const newIndex = items.findIndex((item: any) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    onChange(programs);
  }, [programs, onChange]);

  if (isPending) {
    return (
      <>
        <div className="flex h-12 flex-row items-center justify-center">
          <Spinner aria-label="Loading..." size="lg" theme={blueThemeSpinner} />
        </div>{" "}
      </>
    );
  } else {
    return (
      <>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={programs.map((program: any) => program.id)}
            strategy={verticalListSortingStrategy}
          >
            {programs.map((program: any) => (
              <SortableItem key={program.id} id={program.id}>
                <div className="mt-3 flex flex-row items-center gap-1 rounded-lg border border-gray-400 bg-gray-50 px-4 py-3 text-base text-gray-800 dark:bg-gray-800 dark:text-gray-300">
                  <MdDragIndicator className="inline text-xl text-gray-700" />{" "}
                  <span className="ml-2">{program.title}</span>
                  <div className="ml-auto flex flex-row items-center"></div>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
        <div className="-mb-4 mt-4 flex flex-row items-center text-sm text-gray-700">
          <FaCircleInfo className="mr-2 inline" />
          <span>
            {"The program has to be published to be assigned an order."}
          </span>
        </div>
      </>
    );
  }
};

export default ModalBodyContent;
