/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, type FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actionCreators/auth";
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import AppLogo from "../assets/images/logo.png";

interface RootState {
  auth: any;
}

const LoginPage: FC = function () {
  const dispatch: any = useDispatch();
  const user = useSelector((state: RootState) => state);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    if (cookie.get("token")) {
      navigate("/");

      return;
    }
    if (user.auth.event === "USER_LOGIN_SUCCESS" && cookie.get("token")) {
      navigate("/");
      setIsSubmitting(false);
      setErrors(false);
    } else if (user.auth.event === "USER_LOGIN_FAIL") {
      setIsSubmitting(false);
      setErrors(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    if (email !== null && password !== null) {
      setIsSubmitting(true);
      dispatch(
        login({
          email,
          password,
        }),
      );
    } else {
      setErrors(true);
    }
  };

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
          <a
            href="#"
            className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img className="mr-3 h-6 sm:h-8" src={AppLogo} alt="logo" />
          </a>

          <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
            <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
                Sign in to your account
              </h1>

              {errors && (
                <div
                  className="relative mb-5 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                  role="alert"
                >
                  <span className="block sm:inline">
                    Sorry, we don't recognize your credentials
                  </span>
                  <span
                    className="absolute inset-y-0 right-0 px-4 py-3"
                    onClick={() => {
                      setErrors(false);
                    }}
                  ></span>
                </div>
              )}
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    htmlFor="email"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    id="email"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                    placeholder="name@company.com"
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    onChange={(e: any) => setPassword(e.target.value)}
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-primary-600 focus:ring-primary-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                  />
                </div>

                <button
                  type="button"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                  className="m-auto w-full justify-center rounded-lg bg-primary-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {isSubmitting && (
                    <svg
                      className=" m-auto size-8 animate-spin text-center text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}

                  {isSubmitting ? "" : "Sign in"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
