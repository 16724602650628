import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import { Flowbite } from "flowbite-react";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { enableSentryLogs, isDev, isLocalhost } from "./config/config";
import theme from "./flowbite-theme";
import "./index.css";
import DashboardPage from "./pages";
import ExerciseListPage from "./pages/exercises/list";
import LoginPage from "./pages/login";
import ProgramsListPage from "./pages/programs/list";
import Spotlight from "./pages/spotlight/spotlight";
import TrainersPage from "./pages/trainers/list";
import UserListPage from "./pages/users/list";
import UserProfilePage from "./pages/users/profile";
import VideosListPage from "./pages/videos/list";
import WorkoutsListPage from "./pages/workouts/list";
import NotificationsPage from "./pages/notifications/NotificationsPage";
import { persistor, store } from "./redux//store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const container = document.getElementById("root");

const queryClient = new QueryClient();

if (!container) {
  throw new Error("React root element doesn't exist!");
}

/* Sentry setup */
const enableErrorTesting = () => {
  localStorage.setItem("enableErrorTesting", "true");
};
const disableErrorTesting = () => {
  localStorage.setItem("enableErrorTesting", "false");
};
(window as any).enableErrorTesting = enableErrorTesting;
(window as any).disableErrorTesting = disableErrorTesting;

Sentry.init({
  dsn: "https://0dd070978e421801bc30fee75810bf20@o4506696022163456.ingest.us.sentry.io/4507900720906240",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api-dev\.sweatsonic\.com/,
    /^https:\/\/api\.sweatsonic\.com/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: enableSentryLogs || !isLocalhost,
  environment: isLocalhost ? "dev" : isDev ? "dev" : "prod",
});

/* React setup */
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <Flowbite
          theme={{
            theme,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<LoginPage />} index />
              <Route path="/spotlight" element={<Spotlight />} />
              <Route path="/" element={<DashboardPage />} />
              <Route path="/workouts" element={<WorkoutsListPage />} />
              <Route path="/trainers" element={<TrainersPage />} />
              <Route path="/exercises" element={<ExerciseListPage />} />
              <Route path="/videos" element={<VideosListPage />} />
              <Route path="/programs" element={<ProgramsListPage />} />
              <Route path="/users/list" element={<UserListPage />} />
              <Route path="/users/profile" element={<UserProfilePage />} />
              <Route path="/notifications" element={<NotificationsPage />} />
            </Routes>
          </BrowserRouter>
        </Flowbite>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
);
