import axios from "../util/axios";
import config from "../config/config";

export async function createNewProgram(params: any) {
  return axios.post(`${config.defaults.api_url}/programs/create`, params);
}

export async function updateProgram(params: any, id: any) {
  return axios.post(`${config.defaults.api_url}/programs/update/${id}`, params);
}

export async function deleteProgram(id: any) {
  return axios.delete(`${config.defaults.api_url}/programs/delete/${id}`);
}

export async function programExercises(id: any) {
  return axios.get(`${config.defaults.api_url}/programs/${id}/exercises`);
}

export async function getFeaturedPrograms() {
  return axios.get(`${config.defaults.api_url}/programs/featured`);
}

export async function getTotalPrograms() {
  return axios.get(`${config.defaults.api_url}/programs/metrics`);
}

export async function featuredProgram(params: any, id: any) {
  return axios.post(
    `${config.defaults.api_url}/programs/featured/${id}`,
    params,
  );
}

export async function updateProgramsOrder(params: any) {
  return axios.post(`${config.defaults.api_url}/programs/update-order`, params);
}

export interface GetProgramsParams {
  page: number;
  take: number;
  search?: string;
  visibility?: string;
  short_format?: boolean;
}

export async function getAllPrograms(params: GetProgramsParams): Promise<any> {
  const response = (
    await axios.get<any>(`${config.defaults.api_url}/programs/all`, { params })
  ).data;
  return response;
}
