import type { Dispatch } from "redux";
import config from "../../config/config";
import { ActionType } from "../../redux/actionTypes/index";
import axios from "../../util/axios";

export interface AllExcerciseParams {
  page: number;
  take: number;
  search?: string;
}

export function getAllExercises(params: AllExcerciseParams) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/exercises/all`, { params })
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_ALL_EXERCISES,
            payload: res,
          });
        });
    } catch (error) {
      dispatch({
        type: ActionType.GET_ALL_EXERCISES_FAILED,
        payload: {
          message: "Unable to get exercises",
        },
      });
    }
  };
}
