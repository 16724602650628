import { ActionType } from "../actionTypes/index";

const initialState = {
  workouts: [],
  event: null,
  total: 0,
};

const workoutsReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionType.GET_ALL_WORKOUTS:
      return {
        ...state,
        workouts: action.payload.data.results,
        total: action.payload.data.total,
        event: action.type,
      };
    case ActionType.GET_ALL_WORKOUTS_FAILED:
      return {
        ...state,
        event: action.type,
      };
    default:
      return state;
  }
};

export default workoutsReducer;
