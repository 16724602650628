import { Label } from "flowbite-react";
import { useMemo, useState } from "react";

const DropdownCheckbox = ({
  muscles,
  onChange,
  value: selectedOptions = [],
  disabled,
}: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (id: string) => {
    if (!selectedOptions.includes(id)) {
      onChange({
        target: {
          name: "muscles",
          value: [...selectedOptions, id],
        },
      });
    } else {
      onChange({
        target: {
          name: "muscles",
          value: selectedOptions.filter((optionId: any) => optionId !== id),
        },
      });
    }
  };

  const label = useMemo(() => {
    if (selectedOptions.length === 0) {
      return "Select exercise";
    } else {
      return selectedOptions.map((muscle: any) => muscle.name).join(", ");
    }
  }, [selectedOptions]);

  return (
    <div>
      <Label>Muscle Target</Label>

      <div className="relative mt-1">
        <button
          id="dropdownCheckboxButton"
          data-dropdown-toggle="dropdownDefaultCheckbox"
          className="inline-flex w-full items-center justify-between overflow-hidden rounded-lg border border-gray-300 bg-gray-50 px-3 py-2.5 text-center text-sm font-medium focus:border-blue-500 focus:ring-blue-500 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          disabled={disabled}
          onClick={handleDropdownToggle}
        >
          <div className="text-gray-500">{label}</div>
          <svg
            className="ms-3 size-2.5 text-slate-50 disabled:hidden"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownDefaultCheckbox"
          className={`${
            isDropdownOpen ? "block" : "hidden"
          } absolute z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow dark:divide-gray-600 dark:bg-gray-700`}
        >
          <ul
            className="space-y-3 p-3 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownCheckboxButton"
          >
            {muscles?.map((x: any) => {
              return (
                <li key={x.id}>
                  <button
                    className="flex items-center"
                    onClick={() => handleCheckboxChange(x.id)}
                    role="checkbox"
                    aria-checked={selectedOptions?.includes(x.id)}
                    tabIndex={0}
                  >
                    <input
                      type="checkbox"
                      checked={selectedOptions?.includes(x.id)}
                      className="size-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                    />
                    <label className="ms-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">
                      {x.name}
                    </label>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownCheckbox;
