/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Label,
  Modal,
  Table,
  TextInput,
} from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiHome, HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "../../redux/actionTypes";
import { deleteWorkout } from "../../services/workouts";
import { getAllTrainers } from "../../services/trainers";

import { ToastContainer, toast } from "react-toastify";
import { getWorkouts } from "../../redux/actionCreators/workouts";

import moment from "moment-timezone";

import { Pagination } from "flowbite-react";

import EditWorkoutModal from "./EditWorkoutModal";
import VideosWorkoutModal from "./VideosWorkoutModal";
import AddWorkoutModal from "./AddWorkoutModal";
import { isCancelReq } from "../../util/axios";

interface RootState {
  auth: any;
  users: any;
  workouts: any;
}

const MAX_CONTENT_PER_PAGE = 10;

const WorkoutsListPage: FC = function () {
  const dispatch: any = useDispatch();
  const state: any = useSelector((state: RootState) => state);
  const [workouts, setWorkouts] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [total, setTotal] = useState(0);

  const onPageChnage = (page: number) => {
    setIsLoading(true);
    setCurrentPage(page);
    getWorkoutData(page);
  };
  // const [errors, setErrors] = useState(false);
  // const navigate = useNavigate();

  const getWorkoutData = (page = currentPage) => {
    dispatch(
      getWorkouts({
        page: page,
        take: MAX_CONTENT_PER_PAGE,
        search: search,
      }),
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getWorkoutData();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const trainers: any = await getAllTrainers({});
        setTrainers(trainers.data.results.trainers);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, []);

  const getAllWorkouts = async (page = currentPage) => {
    setIsLoading(true);
    dispatch(
      getWorkouts({
        page: page,
        take: MAX_CONTENT_PER_PAGE,
        search: search,
      }),
    );
  };

  useEffect(() => {
    if (state.workouts.event === ActionType.GET_ALL_WORKOUTS) {
      setIsLoading(false);
      setTotal(state.workouts.workouts.total);
      setWorkouts(state.workouts.workouts);
    } else if (state?.workouts?.type == ActionType.GET_ALL_WORKOUTS_FAILED) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/workouts">Workouts</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              All Workouts
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <form className="lg:pr-3">
                <Label htmlFor="users-search" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    id="users-search"
                    name="users-search"
                    placeholder="Search for workouts"
                    onChange={async (e: any) => {
                      setSearch(e.target.value);
                      setIsLoading(true);
                      dispatch(
                        getWorkouts({
                          page: 0,
                          take: MAX_CONTENT_PER_PAGE,
                          search: e.target.value,
                        }),
                      );
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <AddWorkoutModal
                getWorkouts={getAllWorkouts}
                trainers={trainers}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-y-hidden overflow-x-scroll">
          <div className="inline-block min-w-full align-middle">
            <div className="shadow">
              {loading ? (
                <>
                  <div role="status" className="text-center">
                    <svg
                      aria-hidden="true"
                      className=" mx-auto my-10 size-8  animate-spin fill-blue-600  text-center text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <AllWorkoutsTable
                    workouts={(workouts as any)?.data}
                    state={loading}
                    getWorkouts={getAllWorkouts}
                    trainers={trainers}
                  />
                  {total > 0 && (
                    <div className="mb-8">
                      <Pagination
                        currentPage={currentPage}
                        onPageChange={onPageChnage}
                        showIcons
                        aria-disabled={true}
                        totalPages={Math.ceil(
                          Number((workouts as any)?.total || 1) /
                            MAX_CONTENT_PER_PAGE,
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </NavbarSidebarLayout>
  );
};

const AllWorkoutsTable = function (props: any) {
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {props.workouts?.length ? (
          <>
            {props.workouts.map((workout: any) => {
              return (
                <>
                  <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
                    <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                      <img
                        className="size-10 rounded-full flex-shrink-0"
                        src={workout.cover_image}
                        alt="cover image"
                      />
                      <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        <div className="text-base font-semibold text-gray-900 dark:text-white">
                          {workout.title}
                        </div>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                          {moment(workout.created_at)
                            .tz("America/Los_Angeles")
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                        </div>
                      </div>
                    </Table.Cell>

                    <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
                      <div className="flex items-center">
                        <div
                          className={`mr-2 size-2.5 rounded-full ${
                            workout.published ? "bg-green-400" : "bg-red-400"
                          }`}
                        ></div>{" "}
                        {workout.published ? "Active" : "Not Active"}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-x-3 whitespace-nowrap">
                        <VideosWorkoutModal
                          trainers={props.trainers}
                          workout={workout}
                          getWorkouts={props.getWorkouts}
                        />
                        <EditWorkoutModal
                          trainers={props.trainers}
                          workout={workout}
                          getWorkouts={props.getWorkouts}
                        />
                        <DeleteWorkoutModal
                          workout={workout}
                          getWorkouts={props.getWorkouts}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
              <Table.Cell colSpan={3}>No workouts found</Table.Cell>
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
};

const DeleteWorkoutModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [deleting, setDelete] = useState(false);

  return (
    <>
      <Button color="failure" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-2">
          <HiTrash className="text-lg" />
          Delete Workout
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-6 pb-0 pt-6">
          <span className="sr-only">Delete Workout</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-500" />
            <p className="text-xl text-gray-500">
              Are you sure you want to delete this workout?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                disabled={deleting}
                onClick={async () => {
                  setDelete(true);

                  await deleteWorkout(props.workout.id);

                  await props.getWorkouts();

                  toast.success(
                    `Workout with name ${props.workout.title} deleted`,
                  );

                  setDelete(false);
                  setOpen(false);
                }}
              >
                {deleting ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WorkoutsListPage;
