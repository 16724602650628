import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { updateVideo } from "../../services/videos";
import DropdownCheckbox from "./DropdownCheckbox";
import ExerciseInput from "./ExerciseInput";
import RepsInput from "./RepsInput";
import FileUploadZone, { ImageFile, VideoFile } from "./FileUploadZone";

const EditVideoModal = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  const [video, setVideo] = useState({
    muscle_target: 0,
    workout_video: "",
    exercise_explained_video: "",
    trainer: 0,
    title: "",
    points: 0,
    reps: 0,
    video_duration: 0,
    brief: "",
    category: 0,
    published: false,
    video_thumbnail: "",
    video_thumbnail_type: "",
    workout_video_name: "",
    workout_video_type: "",
    explained_video_name: "",
    explained_video_type: "",
    image_name: "",
    image_type: "",
    exercise: "",
  } as any);

  const fileTypes = ["image/jpg", "image/png", "image/jpeg"];

  const [image, setImage] = useState<ImageFile | null>(null);

  const [explainedVideo, setExplainedVideo] = useState<VideoFile | null>(null);

  const [workoutVideo, setWorkoutVideo] = useState<VideoFile | null>(null);

  const [exerciseTypeId, setExerciseType] = useState<string>();
  const [allMuscles] = useState<any>(props?.commonData?.muscles);
  const [selectedMuscles, setSelectedMuscles] = useState();

  useEffect(() => {
    if (props.commonData && video.exercise) {
      const muscles = props.commonData.exercises.find(
        (e: any) => e.id === video.exercise,
      ).muscles;
      setSelectedMuscles(muscles);
    }
  }, [props.commonData, video.exercise]);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    // Mark the field as touched
    if (!touchedFields.includes(name)) {
      setTouchedFields((prev) => [...prev, name]);
    }

    // Update the video state
    setVideo((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    const {
      trainer,
      title,
      points,
      video_duration,
      brief,
      published,
      setCount,
      repCount,
      exercise,
      equipment,
      musicGenre,
      musicArtist,
      video_thumbnail_image,
      video_filename,
      video_url,
      exercise_explained_video_url,
      exercise_explained_video_filename,
    } = props.video;
    setVideo({
      trainer: trainer?.id || "",
      title: title,
      points: points,
      set_count: setCount,
      rep_count: repCount,
      video_duration: video_duration,
      muscles: exercise?.muscles?.map((e: any) => e?.id) || [],
      exercise: exercise?.id || "",
      equipment: equipment?.id || "",
      music_genre: musicGenre?.id || "",
      music_artist: musicArtist,
      brief: brief,
      published: published,
      video_thumbnail: true as any,
      video_thumbnail_type: "",
      workout_video_type: "",
      explained_video_type: "",
      image_name: "Img-03",
      image_type: "",
    });
    if (props.commonData && exercise?.id) {
      const newTypeId = props.commonData.exercises.find(
        (e: any) => e.id === exercise?.id,
      ).exerciseType.id;

      setExerciseType(newTypeId);
    }

    if (video_thumbnail_image) {
      setImage({
        name: "thumbnail",
        dataUri: video_thumbnail_image,
        type: "image/png",
      });
    }

    if (video_url) {
      setWorkoutVideo({
        storageKey: video_url,
        name: video_filename,
        type: "video/mp4",
      });
    }

    if (exercise_explained_video_url) {
      setExplainedVideo({
        storageKey: exercise_explained_video_url,
        name: exercise_explained_video_filename,
        type: "video/mp4",
      });
    }
  }, [props.video, props.commonData]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Create a payload with only modified fields
      const modifiedData = touchedFields.reduce(
        (result, field) => {
          result[field] = video[field];
          return result;
        },
        {} as Record<string, any>,
      );

      // Conditionally include thumbnail and videos if modified
      if (touchedFields.includes("video_thumbnail")) {
        modifiedData["video_thumbnail"] = image?.dataUri;
        modifiedData["image_name"] = image?.name;
        modifiedData["image_type"] = image?.type;
      }

      if (touchedFields.includes("workout_video")) {
        modifiedData["workout_video"] = workoutVideo?.storageKey;
        modifiedData["workout_video_name"] = workoutVideo?.name;
        modifiedData["workout_video_type"] = workoutVideo?.type;
      }

      if (touchedFields.includes("explained_video")) {
        modifiedData["exercise_explained_video"] = explainedVideo?.storageKey;
        modifiedData["explained_video_name"] = explainedVideo?.name;
        modifiedData["explained_video_type"] = explainedVideo?.type;
      }

      const response = await updateVideo(modifiedData, props.video.id);

      if (response.data.error) {
        toast.error(response.data.message);
      } else {
        props.getVideos();
        toast.success("Video updated successfully!");
        props.onClose();
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const markFieldAsTouched = (field: string) => {
    setTouchedFields((prev) =>
      prev.includes(field) ? prev : [...prev, field],
    );
  };

  const trainerGender = useMemo(() => {
    if (props.commonData?.trainers) {
      const trainerId = parseInt(video.trainer);
      const currentTrainer = props.commonData.trainers.find(
        (e: any) => e.id === trainerId,
      );
      if (currentTrainer) {
        return currentTrainer.gender ? "Female" : "Male";
      }
    }
    return "Gender";
  }, [video, props.commonData]);

  const isSubmitDisabled = useMemo(() => {
    // Get the selected exercise type
    const selectedExerciseType = props.commonData?.exerciseTypes.find(
      (x: any) => x.id === exerciseTypeId,
    );
    const isInstructional = selectedExerciseType?.name === "Instructional";

    // Required Fields - conditionally include fields based on exercise type
    const fieldValidation = [
      "music_genre",
      "music_artist",
      "exercise",
      "video_duration",
      "title",
      "trainer",
    ];

    // Add conditional fields if not instructional
    if (!isInstructional) {
      fieldValidation.push("equipment", "rep_count", "set_count", "points");
    }

    // True if there is an empty field
    const isEmptyField = fieldValidation.some((field: string) => {
      if (Object.hasOwn(video, field)) {
        const len = (video as any)[field]?.length;
        return len === 0;
      }
      return true;
    });

    return (
      isEmptyField ||
      isSubmitting ||
      workoutVideo?.status === "uploading" ||
      explainedVideo?.status === "uploading"
    );
  }, [
    video,
    workoutVideo,
    explainedVideo,
    isSubmitting,
    exerciseTypeId,
    props.commonData,
  ]);

  useEffect(() => {
    if (workoutVideo?.duration) {
      handleChange({
        target: {
          name: "video_duration",
          value: workoutVideo.duration,
        },
      });
    }
  }, [workoutVideo]);

  if (props?.commonData && video.title) {
    return (
      <Modal
        onClose={() => props.onClose()}
        show={true}
        size="3xl"
        position="top-center"
        root={document.body}
      >
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Edit Video</strong>
        </Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <Label htmlFor="firstName">Title</Label>
              <div className="mt-1">
                <TextInput
                  id="title"
                  name="title"
                  placeholder="Title"
                  onChange={handleChange}
                  defaultValue={video.title}
                />
              </div>
            </div>
            <div>
              <Label>{"Brief (Optional)"}</Label>
              <div className="mt-1">
                <Textarea
                  id="brief"
                  name="brief"
                  placeholder="Brief"
                  onChange={handleChange}
                  defaultValue={video.brief}
                />
              </div>
            </div>

            <div>
              <Label>Workout Video</Label>
              <FileUploadZone
                onVideoChange={(video) => {
                  setWorkoutVideo(video);
                  markFieldAsTouched("workout_video");
                }}
                acceptedTypes={["video/mp4"]}
                defaultPreview={{
                  name: workoutVideo?.name,
                  url: workoutVideo?.storageKey,
                  type: "video/mp4",
                }}
                maxSize={200 * 1024 * 1024}
              />
              {workoutVideo?.size && (
                <div className="mt-3 text-[11px]">
                  <p>
                    Total Size: {workoutVideo?.size} MB | Status:{" "}
                    {workoutVideo?.status === "uploading"
                      ? `In Progress ${Math.round(workoutVideo.progress || 0)}%`
                      : "Ready"}
                  </p>
                </div>
              )}
            </div>

            <div>
              <Label>Video Thumbnail</Label>
              <FileUploadZone
                onImageChange={(data) => {
                  setImage(data);
                  markFieldAsTouched("video_thumbnail");
                }}
                acceptedTypes={fileTypes}
                defaultPreview={{
                  name: image?.name || "",
                  url: image?.dataUri,
                  type: image?.type,
                }}
                maxDimensions={{ width: 2000, height: 2000 }}
              />
            </div>

            <div>
              <Label>Select Trainer</Label>
              <div className="mt-1">
                <select
                  onChange={handleChange}
                  name="trainer"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option disabled>Choose</option>
                  {props.commonData?.trainers?.map((x: any) => {
                    return (
                      <option
                        selected={x.id === video.trainer}
                        key={x.id}
                        value={x.id}
                      >
                        {x.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div>
              <Label>Trainer Gender</Label>
              <div className="mt-1">
                <TextInput
                  readOnly
                  disabled
                  id="trainer_gender"
                  name="trainer_gender"
                  placeholder="Type"
                  type="text"
                  value={trainerGender}
                />
              </div>
            </div>

            <DropdownCheckbox
              muscles={allMuscles}
              value={selectedMuscles}
              onChange={handleChange}
              disabled={true}
            />

            <div>
              <Label>Video Duration</Label>
              <div className="mt-1">
                <TextInput
                  id="video_duration"
                  name="video_duration"
                  placeholder="Video Duration"
                  onChange={handleChange}
                  value={video.video_duration}
                />
              </div>
            </div>

            <div>
              <Label>Exercise Type</Label>
              <div className="mt-1">
                <select
                  onChange={(e) => setExerciseType(e.target.value)}
                  name="exercise"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option selected disabled>
                    Choose
                  </option>
                  {props.commonData?.exerciseTypes.map((x: any) => {
                    return (
                      <option
                        key={x.id}
                        value={x.id}
                        selected={x.id === exerciseTypeId}
                      >
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <ExerciseInput
              handleChange={handleChange}
              exerciseTypeId={exerciseTypeId}
              commonData={props?.commonData}
              selected={video.exercise}
            />

            <RepsInput
              exerciseTypeId={exerciseTypeId}
              commonData={props?.commonData}
              handleChange={handleChange}
              value={video.rep_count}
            />

            <div>
              <Label>Sets</Label>
              <div className="mt-1">
                <TextInput
                  id="set_count"
                  name="set_count"
                  placeholder="Sets"
                  onChange={handleChange}
                  type="number"
                  value={video.set_count}
                />
              </div>
            </div>

            <div>
              <Label>Points</Label>
              <div className="mt-1">
                <TextInput
                  id="points"
                  name="points"
                  placeholder="Points"
                  onChange={handleChange}
                  type="number"
                  value={video.points}
                />
              </div>
            </div>

            <div>
              <Label>Equipment</Label>
              <div className="mt-1">
                <select
                  onChange={handleChange}
                  name="equipment"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option selected disabled>
                    Choose
                  </option>
                  {props.commonData?.equipments.map((x: any) => {
                    return (
                      <option
                        key={x.id}
                        value={x.id}
                        selected={x.id === video.equipment}
                      >
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div>
              <Label>Music Genre</Label>
              <div className="mt-1">
                <select
                  onChange={handleChange}
                  name="music_genre"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                  <option selected disabled>
                    Choose
                  </option>
                  {props.commonData?.musicGenres.map((x: any) => {
                    return (
                      <option
                        key={x.id}
                        value={x.id}
                        selected={x.id === video.music_genre}
                      >
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div>
              <Label>Music Artist</Label>
              <div className="mt-1">
                <TextInput
                  id="music_artist"
                  name="music_artist"
                  placeholder="Type"
                  type="string"
                  onChange={handleChange}
                  value={video.music_artist}
                />
              </div>
            </div>

            <div>
              <Label>{"Workout Explaination Video (Optional)"}</Label>
              <FileUploadZone
                onVideoChange={(video) => {
                  setExplainedVideo(video);
                  markFieldAsTouched("explained_video");
                }}
                acceptedTypes={["video/mp4"]}
                defaultPreview={
                  explainedVideo
                    ? {
                        name: explainedVideo?.name,
                        url: explainedVideo?.storageKey,
                        type: "video/mp4",
                      }
                    : undefined
                }
                maxSize={200 * 1024 * 1024}
              />
              {explainedVideo?.size && (
                <div className="mt-3 text-[11px]">
                  <p>
                    Total Size: {explainedVideo?.size} MB | Status:{" "}
                    {explainedVideo?.status === "uploading"
                      ? `In Progress ${Math.round(explainedVideo.progress || 0)}%`
                      : "Ready"}
                  </p>
                </div>
              )}
            </div>

            <div className="flex items-start"></div>
          </div>

          <div>
            <div className="mt-10">
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  name="published"
                  className="peer sr-only"
                  onChange={handleChange}
                  checked={video.published}
                />
                <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Published
                </span>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal
        onClose={() => props.onClose()}
        show={true}
        size="3xl"
        position="top-center"
      >
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
          <strong>Edit Video</strong>
        </Modal.Header>
        <Modal.Body className="flex h-64 items-center justify-center">
          <div role="status">
            <svg
              aria-hidden="true"
              className="size-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default EditVideoModal;
