import { Breadcrumb, Button, Modal, Table } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiHome, HiOutlineExclamationCircle, HiTrash } from "react-icons/hi";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "../../redux/actionTypes";
import { deleteProgram } from "../../services/programs";
import { getAllTrainers } from "../../services/trainers";

import { ToastContainer, toast } from "react-toastify";
import { getPrograms } from "../../redux/actionCreators/programs";
import moment from "moment-timezone";
import { Pagination } from "flowbite-react";
import EditProgramModal from "./EditProgramModal";
import AddProgramModal from "./AddProgramModal";
import EditProgramLibrary from "./EditProgramLibrary";
import { isCancelReq } from "../../util/axios";

interface RootState {
  auth: any;
  users: any;
  workouts: any;
  programs: any;
}

const MAX_CONTENT_PER_PAGE = 10;

export const ProgramDifficultyOptions = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
  { value: "Elite", label: "Elite" },
];

const ProgramsListPage: FC = function () {
  const dispatch: any = useDispatch();
  const state: any = useSelector((state: RootState) => state);
  const [programs, setPrograms] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const onPageChnage = (page: number) => {
    setCurrentPage(page);
    getProgramData(page);
  };

  const getProgramData = (page = currentPage) => {
    setIsLoading(true);
    dispatch(
      getPrograms({
        page: page,
        take: MAX_CONTENT_PER_PAGE,
      }),
    );
  };

  useEffect(() => {
    getProgramData();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const trainers: any = await getAllTrainers({});
        setTrainers(trainers.data.results.trainers);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, []);

  const getProgramsList = async () => {
    setIsLoading(true);
    dispatch(
      getPrograms({
        page: 1,
        take: MAX_CONTENT_PER_PAGE,
      }),
    );
  };

  useEffect(() => {
    if (state.programs.event === ActionType.GET_ALL_PROGRAMS) {
      setIsLoading(false);
      setPrograms(state.programs.programs);
    } else if (state?.programs?.type == ActionType.GET_ALL_PROGRAMS_FAILED) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/programs">Programs</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              All Programs
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100"></div>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <EditProgramLibrary />

              <AddProgramModal
                getPrograms={getProgramsList}
                trainers={trainers}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 flex flex-col">
        <div className="overflow-y-hidden overflow-x-scroll">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {loading ? (
                <>
                  <div role="status" className="text-center">
                    <svg
                      aria-hidden="true"
                      className=" mx-auto my-10 size-8  animate-spin fill-blue-600  text-center text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <AllProgramsTable
                    programs={(programs as any)?.data}
                    state={loading}
                    getPrograms={getProgramsList}
                    trainers={trainers}
                  />
                  {(programs as any)?.total !== MAX_CONTENT_PER_PAGE && (
                    <Pagination
                      currentPage={currentPage}
                      onPageChange={onPageChnage}
                      showIcons
                      aria-disabled={true}
                      totalPages={Math.ceil(
                        Number((programs as any)?.total || 1) /
                          MAX_CONTENT_PER_PAGE,
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </NavbarSidebarLayout>
  );
};

const AllProgramsTable = function (props: any) {
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell>Program</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {props.programs?.length ? (
          <>
            {props.programs?.map((program: any) => {
              return (
                <>
                  <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
                    <Table.Cell className="mr-12 flex items-center space-x-4 whitespace-nowrap p-4 lg:mr-0">
                      <img
                        className="size-10 rounded-full"
                        src={
                          program.cover_image ||
                          "https://production-sweatsonic.s3.amazonaws.com/programs-placeholder"
                        }
                        alt="cover"
                        onError={(e: any) => {
                          e.target.onError = null;
                          e.target.src =
                            "https://production-sweatsonic.s3.amazonaws.com/programs-placeholder";
                        }}
                      />
                      <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        <div className="text-base font-semibold text-gray-900 dark:text-white">
                          {program.title}
                        </div>
                        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                          {moment(program.created_at)
                            .tz("America/Los_Angeles")
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                        </div>
                      </div>
                    </Table.Cell>

                    <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
                      <div className="flex items-center">
                        <div
                          className={`mr-2 size-2.5 rounded-full ${
                            program.published ? "bg-green-400" : "bg-red-400"
                          }`}
                        ></div>{" "}
                        {program.published ? "Active" : "Not Active"}{" "}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-x-3 whitespace-nowrap">
                        <EditProgramModal
                          trainers={props.trainers}
                          program={program}
                          getPrograms={props.getPrograms}
                        />
                        <DeleteProgramModal
                          program={program}
                          getPrograms={props.getPrograms}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Table.Body>
    </Table>
  );
};

const DeleteProgramModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [deleting, setDelete] = useState(false);

  return (
    <>
      <Button color="failure" onClick={() => setOpen(true)}>
        <div className="flex items-center gap-x-2">
          <HiTrash className="text-lg" />
          Delete Program
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size="md">
        <Modal.Header className="px-6 pb-0 pt-6">
          <span className="sr-only">Delete Program</span>
        </Modal.Header>
        <Modal.Body className="px-6 pb-6 pt-0">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-500" />
            <p className="text-xl text-gray-500">
              Are you sure you want to delete this program?
            </p>
            <div className="flex items-center gap-x-3">
              <Button
                color="failure"
                disabled={deleting}
                onClick={async () => {
                  setDelete(true);

                  await deleteProgram(props.program.id);

                  await props.getPrograms();

                  toast.success(
                    `Program with name ${props.program.title} deleted`,
                  );

                  setDelete(false);
                  setOpen(false);
                }}
              >
                {deleting ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProgramsListPage;
