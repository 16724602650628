/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { toast } from "react-toastify";
import { updateTrainer } from "../../services/trainers";
import FavoriteTable, { FavoriteType } from "./FavoriteTable";
import type { FavoriteItem } from "./FavoriteTable";
import FileUploadZone from "../videos/FileUploadZone";

const EditTrainerModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [updating, setUpdate] = useState(false);
  const [trainer, setTrainer]: any = useState({});
  const [favouriteVideos, setFavouriteVideos] = useState<FavoriteItem[]>([]);
  const [favouritePrograms, setFavouritePrograms] = useState<FavoriteItem[]>(
    [],
  );
  const [favouriteWorkouts, setFavouriteWorkouts] = useState<FavoriteItem[]>(
    [],
  );
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const handleChange = (name: string, value: any) => {
    const data: any = {
      ...trainer,
      [name]: value,
    };
    setTrainer(data);
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleFavoritesChange = (type: string, items: FavoriteItem[]) => {
    if (type === FavoriteType.Videos) {
      setFavouriteVideos(items);
    } else if (type === FavoriteType.Workouts) {
      setFavouriteWorkouts(items);
    } else if (type === FavoriteType.Programs) {
      setFavouritePrograms(items);
    }
    setTouched({
      ...touched,
      [type]: true,
    });
  };

  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.trainer) {
      setTrainer({
        description: props.trainer.description,
        monthly_plays: props.trainer.monthly_plays,
        followers: props.trainer.total_followers,
        social_ig: props.trainer.social_ig,
        social_tt: props.trainer.social_tt,
        social_fb: props.trainer.social_fb,
        social_tw: props.trainer.social_tw,
        profile_image: props.trainer.profile_image,
        cover_image: props.trainer.cover_image,
        thumb_image: props.trainer.thumb_image,
        status: props.trainer.status,
      });
      setFavouriteVideos(props.trainer.favourite_videos || []);
      setFavouriteWorkouts(props.trainer.favourite_workouts || []);
      setFavouritePrograms(props.trainer.favourite_programs || []);
    }
  }, [props.trainer]);

  const validateForm = () => {
    if (
      touched["monthly_plays"] &&
      (!trainer.monthly_plays ||
        isNaN(trainer.monthly_plays) ||
        trainer.monthly_plays < 0)
    ) {
      toast.error(
        "Monthly plays must be a positive number and cannot be empty",
      );
      return false;
    }

    if (
      touched["followers"] &&
      (!trainer.followers || isNaN(trainer.followers) || trainer.followers < 0)
    ) {
      toast.error(
        "Total followers must be a positive number and cannot be empty",
      );
      return false;
    }

    const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
    if (
      touched["social_ig"] &&
      trainer.social_ig &&
      !urlRegex.test(trainer.social_ig)
    ) {
      toast.error("Instagram URL is invalid");
      return false;
    }
    if (
      touched["social_tt"] &&
      trainer.social_tt &&
      !urlRegex.test(trainer.social_tt)
    ) {
      toast.error("TikTok URL is invalid");
      return false;
    }
    if (
      touched["social_fb"] &&
      trainer.social_fb &&
      !urlRegex.test(trainer.social_fb)
    ) {
      toast.error("Facebook URL is invalid");
      return false;
    }
    if (
      touched["social_tw"] &&
      trainer.social_tw &&
      !urlRegex.test(trainer.social_tw)
    ) {
      toast.error("X (Twitter) URL is invalid");
      return false;
    }

    if (
      touched["description"] &&
      trainer.description &&
      trainer.description.length > 500
    ) {
      toast.error("Bio must be less than 500 characters");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setUpdate(true);

    const updatedTrainerData = {
      ...trainer,
      favourite_videos_ids: favouriteVideos.map((item) => item.id),
      favourite_workouts_ids: favouriteWorkouts.map((item) => item.id),
      favourite_programs_ids: favouritePrograms.map((item) => item.id),
    };

    const filteredTrainerData = Object.keys(updatedTrainerData)
      .filter((key) => touched[key] || key.includes("favourite"))
      .reduce((obj: { [key: string]: any }, key) => {
        obj[key] = updatedTrainerData[key];
        return obj;
      }, {});

    const update_trainer = await updateTrainer(
      filteredTrainerData,
      props.trainer?.id,
    );

    if (update_trainer.data.error) {
      toast.error(update_trainer.data.message);
    } else {
      props.getTrainers();
      setOpen(false);
      toast.success("Trainer updated successfully!");
    }

    setUpdate(false);
  };

  const isModified = useMemo(() => {
    const trainerChanged = Object.keys(trainer).some(
      (key) => touched[key] && trainer[key] !== props.trainer[key],
    );

    const videosChanged =
      JSON.stringify(favouriteVideos) !==
      JSON.stringify(props.trainer.favourite_videos);

    const workoutsChanged =
      JSON.stringify(favouriteWorkouts) !==
      JSON.stringify(props.trainer.favourite_workouts);

    const programsChanged =
      JSON.stringify(favouritePrograms) !==
      JSON.stringify(props.trainer.favourite_programs);

    return (
      trainerChanged || videosChanged || workoutsChanged || programsChanged
    );
  }, [
    trainer,
    favouriteVideos,
    props.trainer,
    favouriteWorkouts,
    favouritePrograms,
    touched,
  ]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-2">
            <HiOutlinePencilAlt className="text-lg" />
            Edit Athlete
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          size="3xl"
          position="top-center"
          root={rootRef.current ?? undefined}
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Edit Athlete</strong>
          </Modal.Header>
          <Modal.Body className="overflow-visible">
            <div className="mb-1 block">
              <Label htmlFor="firstName">Bio</Label>
              <div className="mt-1">
                <Textarea
                  id="comment"
                  name="description"
                  required
                  rows={1}
                  defaultValue={props.trainer.description}
                  placeholder="Bio"
                  onChange={({ target: { name, value } }) =>
                    handleChange(name, value)
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 overflow-visible sm:grid-cols-2">
              <div>
                <Label htmlFor="firstName">Monthly Plays</Label>
                <div className="mt-1">
                  <TextInput
                    id="monthly_plays"
                    name="monthly_plays"
                    defaultValue={props.trainer.monthly_plays}
                    placeholder="Monthly Plays"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Total Followers</Label>
                <div className="mt-1">
                  <TextInput
                    id="followers"
                    name="followers"
                    defaultValue={props.trainer.total_followers}
                    placeholder="Followers"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Instagram</Label>
                <div className="mt-1">
                  <TextInput
                    id="instagram"
                    name="social_ig"
                    defaultValue={props.trainer.social_ig}
                    placeholder="Instagram"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">TikTok</Label>
                <div className="mt-1">
                  <TextInput
                    id="tiktok"
                    name="social_tt"
                    defaultValue={props.trainer.social_tt}
                    placeholder="Tiktok"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">Facebook</Label>
                <div className="mt-1">
                  <TextInput
                    id="facebook"
                    name="social_fb"
                    defaultValue={props.trainer.social_fb}
                    placeholder="Facebook"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="firstName">X (formerly Twitter)</Label>
                <div className="mt-1">
                  <TextInput
                    id="x"
                    name="social_tw"
                    defaultValue={props.trainer.social_tw}
                    placeholder="X"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                  />
                </div>
              </div>

              <div>
                <Label>Profile picture</Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpg",
                    "image/png",
                    "image/jpeg",
                    "image/webp",
                  ]}
                  onImageChange={(image) => {
                    handleChange("profile_image", {
                      uri_data: image.dataUri,
                      file_type: image.type,
                      file_name: image.name,
                    });
                  }}
                  defaultPreview={{
                    url: props.trainer.profile_image,
                  }}
                  maxDimensions={{ width: 2000, height: 2000 }}
                  maxSize={10 * 1024 * 1024}
                />
              </div>

              <div>
                <Label>Cover Image</Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpg",
                    "image/png",
                    "image/jpeg",
                    "image/webp",
                  ]}
                  onImageChange={(image) => {
                    handleChange("cover_image", {
                      uri_data: image.dataUri,
                      file_type: image.type,
                      file_name: image.name,
                    });
                  }}
                  defaultPreview={{
                    url: props.trainer.cover_image,
                  }}
                  maxDimensions={{ width: 2000, height: 2000 }}
                  maxSize={10 * 1024 * 1024}
                />
              </div>

              <div>
                <Label>Thumb picture</Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpg",
                    "image/png",
                    "image/jpeg",
                    "image/webp",
                  ]}
                  onImageChange={(image) => {
                    handleChange("thumb_image", {
                      uri_data: image.dataUri,
                      file_type: image.type,
                      file_name: image.name,
                    });
                  }}
                  defaultPreview={{
                    url: props.trainer.thumb_image,
                  }}
                  maxDimensions={{ width: 2000, height: 2000 }}
                  maxSize={10 * 1024 * 1024}
                />
              </div>

              <div>
                <div className="mt-1 flex w-64 flex-col space-y-4 pb-4 pl-1">
                  <Label>Additional options</Label>
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      name="status"
                      className="peer sr-only"
                      onChange={({ target: { name, checked } }) =>
                        handleChange(name, String(checked))
                      }
                      defaultChecked={props?.trainer?.status === 1}
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Status
                    </span>
                  </label>
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      name="squad"
                      className="peer sr-only"
                      onChange={({ target: { name, checked } }) =>
                        handleChange(name, String(checked))
                      }
                      defaultChecked={props?.trainer?.squad}
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Squad
                    </span>
                  </label>
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      name="featured"
                      className="peer sr-only"
                      onChange={({ target: { name, checked } }) =>
                        handleChange(name, String(checked))
                      }
                      defaultChecked={props?.trainer?.featured}
                    />
                    <div className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Featured
                    </span>
                  </label>
                </div>
              </div>

              <div className="">
                <Label>Favorite Workouts</Label>
                <FavoriteTable
                  items={favouriteWorkouts}
                  onClearAll={() =>
                    handleFavoritesChange(FavoriteType.Workouts, [])
                  }
                  onRemove={(id: number) => {
                    handleFavoritesChange(
                      FavoriteType.Workouts,
                      favouriteWorkouts.filter((workout) => workout.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    handleFavoritesChange(
                      FavoriteType.Workouts,
                      favouriteWorkouts.concat(item),
                    )
                  }
                  type={FavoriteType.Workouts}
                />
              </div>
              <div className="">
                <Label>Favorite Videos</Label>
                <FavoriteTable
                  items={favouriteVideos}
                  onClearAll={() =>
                    handleFavoritesChange(FavoriteType.Videos, [])
                  }
                  onRemove={(id: number) => {
                    handleFavoritesChange(
                      FavoriteType.Videos,
                      favouriteVideos.filter((video) => video.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    handleFavoritesChange(
                      FavoriteType.Videos,
                      favouriteVideos.concat(item),
                    )
                  }
                  type={FavoriteType.Videos}
                />
              </div>
              <div className="">
                <Label>Favorite Programs</Label>
                <FavoriteTable
                  items={favouritePrograms}
                  onClearAll={() =>
                    handleFavoritesChange(FavoriteType.Programs, [])
                  }
                  onRemove={(id: number) => {
                    handleFavoritesChange(
                      FavoriteType.Programs,
                      favouritePrograms.filter((program) => program.id !== id),
                    );
                  }}
                  onSelect={(item: FavoriteItem) =>
                    handleFavoritesChange(
                      FavoriteType.Programs,
                      favouritePrograms.concat(item),
                    )
                  }
                  type={FavoriteType.Programs}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              disabled={updating || !isModified}
              onClick={handleSubmit}
            >
              {updating ? "Updating..." : "Update"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditTrainerModal;
