import { ActionType } from "../actionTypes/index";

const initialState = {
  all: [],
  total: 0,
  event: null,
};

const exercisesReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionType.GET_ALL_EXERCISES:
      return {
        ...state,
        all: action.payload.data.results.data,
        total: action.payload.data.results.total,
        event: action.type,
      };
    case ActionType.GET_ALL_USERS_FAILED:
      return {
        ...state,
        event: action.type,
      };
    default:
      return state;
  }
};

export default exercisesReducer;
