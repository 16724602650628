import axios from "../../util/axios";
import { ActionType } from "../actionTypes/index";
import type { Dispatch } from "redux";
import config from "../../config/config";

export function getUsers(params: any) {
  return async function (dispatch: Dispatch) {
    // Destructure signal if we want to pass it to axios
    const { signal, ...restParams } = params;

    try {
      await axios
        .get(`${config.defaults.api_url}/user/all`, {
          params: restParams,
          signal: signal,
        })
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_ALL_USERS,
            payload: res,
          });
        });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ActionType.GET_ALL_USERS_FAILED,
        payload: {
          message: "Unable to get users",
        },
      });
    }
  };
}
